$(() => {
  $('.form-dynamic').each((i, item) => {
    const $form = $(item);
    const $editAction = $form.find('.form-dynamic-edit');
    const $cancelAction = $form.find('.form-dynamic-cancel');

    const affectedElements = '.form-control, .custom-control-input';

    if ($form.hasClass('form-dynamic-disabled')) {
      $form.find(affectedElements).attr('disabled', true);
    }

    if ($form.data('edit')) {
      $form.removeClass('form-dynamic-disabled');
      $('.form-control').attr('disabled', false);
    }

    $editAction.on('click', (e) => {
      e.preventDefault();

      $form.removeClass('form-dynamic-disabled');

      $form.find(affectedElements).attr('disabled', false);
    });

    $cancelAction.on('click', (e) => {
      e.preventDefault();

      $form.addClass('form-dynamic-disabled');

      $form.find(affectedElements).attr('disabled', true);
    });
  });
});
