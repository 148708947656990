$(() => {
  $('.query-region').each((i, item) => {
    const $this = $(item);
    const goTo = $this.data('go-to');

    $this.on('select2:select', (e) => {
      const $element = $(e.params.data.element);
      const value = $element.val();

      const params = new URLSearchParams(window.location.search);
      params.set('region', value);
      params.set('this-week-page', 1);
      params.set('next-week-page', 1);
      params.set('permanents-page', 1);

      const url = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
      window.location = `${url}?${params.toString()}${(goTo || '')}`;
    });
  });
});
