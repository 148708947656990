import { DEFAULT_ICON_OPTIONS } from '../common/constants';

$(() => {
  $('.section-venue').each((i, item) => {
    const $container = $(item).find('.behavior-display-affected');
    const $select = $container.find('#id_venue');
    const $clear = $container.find('.behavior-clear-venue');
    const $map = $container.find('.input-map');

    const autofill = ['address', 'commune', 'region'];

    let mapMarker;

    $clear.on('click', () => {
      $select.val(null).trigger('change');
      autofill
        .forEach((field) => {
          $(`#id_${field}_view`).val(null);
          $(`#id_${field}`).val(null).trigger('change');
        });

      $map
        .data('instance')
        .setView([-33.4377156, -70.6515013], 13);
    });

    $select.on('select2:select', (e) => {
      const $element = $(e.params.data.element);
      const url = $element.data('url');

      if (url) {
        axios
          .get(url)
          .then((response) => {
            autofill
              .forEach(field => $(`#id_${field}_view`).val(response.data[field]));

            const {
              lat,
              lon
            } = response.data;
            const coords = [lat, lon];

            $map
              .data('instance')
              .flyTo(coords, 17);

            if (mapMarker) {
              mapMarker.setLatLng(coords);
            } else {
              mapMarker = L.marker.svgMarker(
                coords,
                { iconOptions: DEFAULT_ICON_OPTIONS }
              )
                .addTo($map.data('instance'));
            }
          })
          .catch(error => console.log(error));
      }
    });
  });
});
