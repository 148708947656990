// Metrics constants

const deviceIsMobile = $(document).width() < 992;

const PRIMARY_COLOR = '#1fce6b';
const GRIDLINES_COLOR = '#32d579';
const DEFAULT_TEXT_STYLES = {
  color: '#4a4a4a',
  fontName: 'Poppins',
  fontSize: deviceIsMobile ? 12 : 14
};

export const DEFAULT_CHART_OPTIONS = {
  legend: 'none',
  curveType: 'none',
  width: '100%',
  height: '100%',
  chartArea: {
    top: deviceIsMobile ? 16 : 48,
    width: deviceIsMobile ? '68%' : '85%',
    height: deviceIsMobile ? 102 : 300
  },
  colors: [PRIMARY_COLOR],
  backgroundColor: 'transparent',
  lineWidth: 2,
  pointSize: deviceIsMobile ? 2 : 10,
  tooltip: {
    isHtml: true,
    trigger: 'both'
  },
  hAxis: {
    showTextEvery: 7,
    slantedText: false,
    textStyle: DEFAULT_TEXT_STYLES
  },
  vAxis: {
    format: 'short',
    gridlines: {
      color: GRIDLINES_COLOR
    },
    maxValue: 100,
    minValue: 0,
    minorGridlines: {
      count: 0
    },
    textStyle: DEFAULT_TEXT_STYLES
  }
};

// Metrics selectors

export const METRICS_FORM_SELECTOR = '.metrics-form';
export const METRICS_CHART_CONTAINER_SELECTOR = '.metrics-chart-container';
export const METRICS_RESULTS_CONTAINER_SELECTOR = '.metrics-results-container';

// Metrics messages

const ERROR_PREFIX = 'METRICS ERROR:';
export const REQUEST_ERROR = `${ERROR_PREFIX} Could not fetch data.'`;
export const MISSING_CONTAINER_ERROR = `${ERROR_PREFIX} There is no container to append results.`;

// Metrics templates

export const LOADING_TEMPLATE = `
  <div class="text-center mt-6">
    <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">
      <span class="sr-only">Cargando...</span>
    </div>
  </div>
`;

export const ERROR_TEMPLATE = `
  <div class="text-center mt-6">
    <span class="material-icons text-danger" style="font-size: 3rem;" aria-hidden="true">report_problem</span>
    <p role="status">No se ha podido cargar la información, por favor, vuelve a intentar más tarde.</p>
  </div>
`;

export const SECTION_CHART_TEMPLATE = `
  <section class="section-colored chart-container p-0 mb-7">
  </section>
`;

const FIGURES_LIST = `
  <ul class="section-body list-unstyled" id="figures">
    <li class="section-figures-item">
      <div class="section-figures-item-title">
        <span class="icon material-icons" aria-hidden="true">favorite</span>
        <span data-favorites-total ></span>
      </div>
      Favoritos
    </li>
    <li class="section-figures-item">
      <div class="section-figures-item-title">
        <span class="icon material-icons" aria-hidden="true">share</span>
        <span data-shared-on-social-networks-total ></span>
      </div>
      Compartidos
    </li>
    <li class="section-figures-item">
      <div class="section-figures-item-title">
        <span class="icon material-icons" aria-hidden="true">account_circle</span>
        <span data-attendances-total ></span>
      </div>
      Asistiré
    </li>
    <li class="section-figures-item">
      <div class="section-figures-item-title">
        <span class="icon material-icons" aria-hidden="true">today</span>
        <span data-event-scheduled-on-google-calendar-total ></span>
      </div>
      Agendados
    </li>
  </ul>
`;

export const SECTION_EVENT_FIGURES_TEMPLATE = `
  <section class="section-colored section-figures mb-7">
    <div class="row align-items-lg-center">
      <div class="col-lg-3 col-xl-4">
        <div class="section-header">
          <h3 class="section-figures-title" aria-describedby="figures">Cifras de tu evento</h3>
        </div>
      </div>
      <div class="col-lg-9 col-xl-8">
        ${FIGURES_LIST}
      </div>
    </div>
  </section>
`;

export const SECTION_FIGURES_TEMPLATE = `
  <section class="section-colored section-figures mb-7">
    <div class="row align-items-lg-stretch">
      <div class="col-lg-3 col-xl-4">
        <div class="section-header h-100 d-flex align-items-center justify-content-center justify-content-lg-start border-lg-right border-accent">
          <h3 class="section-figures-title font-weight-normal" aria-describedby="figures">
            Tienes <span class="text-primary lead font-weight-bold" data-event-total ></span> eventos
          </h3>
        </div>
      </div>
      <div class="col-lg-9 col-xl-8">
        ${FIGURES_LIST}
      </div>
    </div>
  </section>
`;

export const SECTION_DATA_TEMPLATE = `
  <section class="section-colored">
    <div class="section-header mb-5">
      <h3 class="lead font-weight-bold mb-0" data-header ></h3>
      <small class="font-level-10 font-weight-normal text-gray-b">Visitas - <span data-date-filter ></span></small>
    </div>
    <div class="section-body">
      <ul class="list-group list-group-flush"></ul>
    </div>
  </section>
`;

export const SECTION_DATA_ITEM_TEMPLATE = `
  <li class="list-group-item">
    <div class="d-flex flex-nowrap">
      <div class="flex-grow-1 text-break" data-name ></div>
      <div class="flex-shrink-0 d-inline-flex justify-content-end">
        <div class="percentage d-none d-lg-block">
          <div class="percentage-bar"></div>
        </div>
        <span class="percentage-text mx-4 text-gray-b" data-percentage ></span>
      </div>
      <div class="flex-shrink-0 font-level-9 font-weight-normal text-gray-b text-right" style="width: 5em;" data-total ></div>
    </div>
  </li>
`;

export const SECTION_DATA_COLLAPSE_BUTTON_TEMPLATE = `
  <div class="text-center">
    <button class="btn btn-link text-underline mt-1" type="button" data-toggle="collapse" data-target="" aria-expanded="false" aria-controls="">
      <span class="expanded-false">Ver más</span>
      <span class="expanded-true">Ver menos</span>
    </button>
  </div>
`;

export const SECTION_DATA_MOBILE_TEMPLATE = `
  <section class="section-colored">
    <div class="section-header mb-5">
      <h3 class="lead font-weight-bold mb-0">Métricas de APP Móvil</h3>
      <small class="font-level-10 font-weight-normal text-gray-b">Visitas - Desde hace 30 días</small>
    </div>
    <div class="section-body p-4">
      <span data-mobile_metric></span>
    </div>
  </section>
`;