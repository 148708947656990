/**
 * A function for change select2's text color
 * if selected option is default or not
 *
 * @return {void}
 */
export function changeSelect2TextColor() {
  const $this = $(this);
  const $select2SelectionRendered = $this.next().find('.select2-selection__rendered');

  if ($this.val()) {
    $select2SelectionRendered.removeClass('text-accent');
  } else {
    $select2SelectionRendered.addClass('text-accent');
  }
}

/**
 * A function for change multiple select2's remove icon
 *
 * @return {void}
 */
export function changeRemoveIconToMultipleSelect2() {
  $(this).parent().find('.select2-selection__choice__remove')
    .addClass('material-icons icon')
    .text('clear');
}

/**
 * A function for reset select2's option
 *
 * @return {void}
 */
export function resetSelect(select) {
  $(select).val(null).trigger('change');
}

/**
 * A function for reset tempus dominus input
 *
 * @return {void}
 */
export function resetDateTimePicker(input) {
  $(input).datetimepicker('clear');
}

/**
 * A function for reset checkbox input
 *
 * @return {void}
 */
export function resetCheckbox(input) {
  input.removeAttribute('checked');
}

$(() => {
  const $datetimePicker = $('.datetimepicker-input');

  $datetimePicker.each((_, item) => {
    const $this = $(item);
    $this.data('target', `#${$this.prop('id')}`);

    $this.datetimepicker({
      date: moment($this.val(), $this.data('format')),
      format: $this.data('format'),
      locale: 'es'
    });
  });

  $('.model-form input:text').addClass('form-control');

  // Select 2 initialization
  $('select').not('.js-not-select2').each((_, item) => {
    const $this = $(item);

    $this.attr('data-placeholder', $this.attr('placeholder'));

    $this.select2({
      width: '100%',
      height: '100%',
      language: {
        noResults: () => 'Sin resultados.'
      },
      maximumSelectionLength: $this.attr('name') === 'disciplines' ? 3 : null
    });

    if ($this.is('[multiple]')) {
      changeRemoveIconToMultipleSelect2.call(item);
      $this.on('change', changeRemoveIconToMultipleSelect2);
    } else if (!$this.is('[class*="form-control-"]')) {
      changeSelect2TextColor.call(item);
      $this.on('change', changeSelect2TextColor);
    }
  });

  $('form')
    .submit((e) => {
      const $this = $(e.currentTarget);
      const $buttons = $this.find(':submit').not('.js-do-not-disable-on-submit');

      // disable buttons after submit to prevent disabling submit inputs
      // with values
      setTimeout(() => {
        $buttons.addClass('disabled').prop('disabled', true);
        window.App.utils.showLoading($buttons);

        setTimeout(() => {
          $buttons.removeClass('disabled').prop('disabled', false);
          window.App.utils.hideLoading();
        }, 3000);
      }, 10);
    })
    .on('reset', (e) => {
      const form = e.currentTarget;
      const formElements = [...form.elements];

      // Reset all kind of inputs
      formElements
        .forEach((input) => {
          const isSelect2 = $(input).is('select:not(.js-not-select2)');
          const isDateTimePicker = $(input).is('.datetimepicker-input');
          const isCheckbox = $(input).is('[type="checkbox"], [type="radio"]');

          if (isSelect2) {
            resetSelect(input);
          }

          if (isDateTimePicker) {
            resetDateTimePicker(input);
          }

          if (isCheckbox) {
            resetCheckbox(input);
          }
        });

      // Submit form here, cause manually it will not send empty value
      // if field has select2 initialized
      $(form).trigger('submit');
    });
});
