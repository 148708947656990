
// forEach function from Todd Motto's  blog, as mentioned above
const forEach = function (array, callback, scope) {
  for (let i = 0; i < array.length; i++) {
    callback.call(scope, i, array[i]); // passes back stuff we need
  }
};

window.addEventListener('DOMContentLoaded', (event) => {
  const config = {
    items: 5,
    slideBy: 1,
    autoplay: true,
    gutter: 20,
    navPosition: 'bottom',
    loop: true,
    mouseDrag: true,
    nav: false,
    navAsThumbnails: true,
    autoplayButtonOutput: false,
    responsive: {
      1000: {
        items: 5
      },
      700: {
        items: 4,
        gutter: 30
      },
      640: {
        items: 3,
        edgePadding: 20,
        gutter: 20
      },
      500: {
        items: 2
      },
      100: {
        items: 2
      }
    }
  };

  const sliders_circle = document.querySelectorAll('.slider-carousel-circle');
  forEach(sliders_circle, (index, value) => {
    // now fetch YOUR-SLUG from html (use value.getAttribute('data-name') for < IE10 support)
    let thumbnail = value.dataset.controls;
    if (thumbnail === undefined) {
      return;
    }
    const slider = tns({
      container: value,
      prevButton: `.prev-circle-${thumbnail}`,
      nextButton: `.next-circle-${thumbnail}`,
      ...config
    });
  });


  const sliders_square = document.querySelectorAll('.slider-carousel-square');
  forEach(sliders_square, (index, value) => {
    // now fetch YOUR-SLUG from html (use value.getAttribute('data-name') for < IE10 support)
    let thumbnail = value.dataset.controls;
    if (thumbnail === undefined) {
      return;
    }
    const slider = tns({
      container: value,
      prevButton: `.prev-square-${thumbnail}`,
      nextButton: `.next-square-${thumbnail}`,
      ...config
    });
  });
  const sliders_vertical = document.querySelectorAll('.slider-carousel-vertical');
  forEach(sliders_vertical, (index, value) => {
    // now fetch YOUR-SLUG from html (use value.getAttribute('data-name') for < IE10 support)
    let thumbnail = value.dataset.controls;
    if (thumbnail === undefined) {
      return;
    }
    const slider = tns({
      container: value,
      prevButton: `.prev-vertical-${thumbnail}`,
      nextButton: `.next-vertical-${thumbnail}`,
      ...config
    });
  });

  $('.slider-carousel-streaming').ready(() => {
    const config_streaming = {
      items: 1,
      itemsDesktop: [1199, 1],
      itemsDesktopSmall: [979, 1],
      itemsTablet: [768, 1],
      itemsMobile: [599, 1],
      navigation: false,
      pagination: true,
      lazyLoad: true,
      theme: 'my-owl-theme',
      autoHeight: true
    };

    $('.slider-carousel-streaming').owlCarousel({ ...config_streaming });
  });

  $('.slider-carousel-home-sections').ready(() => {
    const config_sections = {
      items: 3,
      itemsDesktop: [1199, 3],
      itemsDesktopSmall: [979, 3],
      itemsTablet: [768, 2],
      itemsMobile: [599, 1],
      navigationText: ['&#139;', '&#155;'],
      navigation: true,
      lazyLoad: true,
      theme: 'my-theme'

    };

    $('.slider-carousel-home-sections').owlCarousel({ ...config_sections });
  });

  $('.slider-carousel-events').ready(() => {
    const config_events = {
      items: 4,
      itemsDesktop: [1199, 4],
      itemsDesktopSmall: [979, 3],
      itemsTablet: [768, 2],
      itemsMobile: [599, 1],
      navigationText: ['&#139;', '&#155;'],
      navigation: true,
      lazyLoad: true,
      theme: 'my-owl-theme'
    };

    $('.slider-carousel-events').owlCarousel({ ...config_events });
  });

  $('.slider-carousel-link').ready(() => {
    const config_links = {
      items: 4,
      itemsDesktop: [1199, 4],
      itemsDesktopSmall: [979, 3],
      itemsTablet: [768, 2],
      itemsMobile: [599, 1],
      navigation: false,
      pagination: true,
      lazyLoad: false,
      theme: 'my-owl-theme'

    };
    $('.slider-carousel-link').owlCarousel({ ...config_links });
  });

  $('#featuredEvents').each(() => {
    const carousel_square = tns({
      container: '#featuredEventContainer',
      items: 2,
      autoplay: true,
      gutter: 20,
      nav: false,
      slideBy: 1,
      mouseDrag: true,
      navAsThumbnails: true,
      autoplayButtonOutput: false,
      controlsPosition: 'bottom',
      navPosition: 'botton',
      prevButton: '.featured-prev',
      nextButton: '.featured-next',
      responsive: {
        600: {
          items: 2
        },
        200: {
          items: 1
        }
      }
    });
  });
});
