$(() => {
  $('.email-input-group').each((i, item) => {
    const $this = $(item);
    const $button = $this.find('.default-btn');
    const $input = $this.find('textarea');
    const $alert = $this.find('.ckeditor-alert');
    const ckeditorInstance = CKEDITOR.instances[$input.prop('id')];
    const url = $button.data('url');

    if (url) {
      $button.on('click', () => {
        axios.get(url)
          .then((res) => {
            ckeditorInstance.setData(res.data.email_text);
            $alert.fadeIn();
            setTimeout(() => {
              $alert.fadeOut();
            }, 3500);
          });
      });
    }
  });
});
