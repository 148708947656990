window.addEventListener('load', () => {
  const setAspectRatio = () => {
    $('.child-aspect-ratio').each((i, item) => {
      const $this = $(item);
      const $iframe = $(item).find('iframe');

      const originalWidth = $iframe.width();
      const originalHeight = $iframe.height();

      // 30 = default grid-gutter-width of bootstrap
      const parentWidth = parseFloat($this.innerWidth()) - 30;

      $iframe
        .attr('width', parentWidth)
        .attr('height', parentWidth * originalHeight / originalWidth)
        .addClass('visible');
    });
  };

  setAspectRatio();

  $(window).smartresize(() => {
    setAspectRatio();
  });
});
