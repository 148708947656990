$(() => {
  $('.behavior-delete-request').each((_, item) => {
    const $this = $(item);

    const target = $this.data('target');
    const $target = target ? $this.closest(target) : $this;

    let options = {};
    const url = $this.data('url');
    const isEvent = $this.hasClass('behavior-event-delete-request');

    if (isEvent) {
      options = {
        params: { favorited: 'false' }
      };
    }

    $this.on('click', () => {
      axios
        .get(url, options)
        .then(() => {
          $target.remove();
        })
        .catch((error) => {
          console.log(error);
        });
    });
  });
});
