$(() => {
  $('.behavior-input-trigger').each((_, item) => {
    const $this = $(item);
    const $input = $($this.data('target'));

    if ($input.length < 0) return;

    $this.on('click', () => ($input.click()));
  });
});
