import { CONDITION_VALUE_DATA_ATTR, FAVORITE_URL_DATA_ATTR } from '../common/constants';

const CONDITION_VALUE_ATTR = `data-${CONDITION_VALUE_DATA_ATTR}`;

$(() => {
  $('.favorite-btn').click(function onClickFavoriteButton() {
    const $this = $(this);

    // Get condition value
    const conditionValue = $this.attr(CONDITION_VALUE_ATTR);
    const newConditionValue = conditionValue === 'true' ? 'false' : 'true';

    // Update condittion value for instant feedback to user
    this.setAttribute(CONDITION_VALUE_ATTR, newConditionValue);

    // Send request
    const url = $this.data(FAVORITE_URL_DATA_ATTR);
    const options = {
      params: { favorited: newConditionValue }
    };

    axios
      .get(url, options)
      .catch(() => {
        // Restore condition value
        this.setAttribute(CONDITION_VALUE_ATTR, conditionValue);
      });
  });
});
