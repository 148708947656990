import { API_CHART_URL } from '../../common/constants';
import {
  DEFAULT_CHART_OPTIONS,
  ERROR_TEMPLATE,
  LOADING_TEMPLATE,
  METRICS_RESULTS_CONTAINER_SELECTOR,
  MISSING_CONTAINER_ERROR,
  REQUEST_ERROR,
  SECTION_CHART_TEMPLATE,
  SECTION_DATA_COLLAPSE_BUTTON_TEMPLATE,
  SECTION_DATA_ITEM_TEMPLATE,
  SECTION_DATA_TEMPLATE,
  SECTION_EVENT_FIGURES_TEMPLATE,
  SECTION_FIGURES_TEMPLATE,
  SECTION_DATA_MOBILE_TEMPLATE
} from './constants';

// Metrics functions

/**
 * A function to draw a chart with data.
 *
 * @param  {array} data
 * @return {object} chartContainer
 */
const drawChart = (data, chartContainer) => {
  const dataTable = google.visualization.arrayToDataTable(data);

  const chart = new google.visualization.LineChart(chartContainer);

  chart.draw(dataTable, DEFAULT_CHART_OPTIONS);
};


const createSectionDataMobile = (visits) => {
  // Create new section data mobile
  const $newSectionData = $(SECTION_DATA_MOBILE_TEMPLATE);
  $newSectionData.find('[data-mobile_metric]')
    .text(`En los ultimos 30 días, ${visits} personas han utilizado la aplicación Móvil.`);
  return $newSectionData;
};


/**
 * A function to create a section data.
 *
 * @param  {array} data
 * @param  {number} maxVisits
 * @return {object}
 */
const createSectionData = ([headers, ...data]) => {
  // Create new section data
  const $newSectionData = $(SECTION_DATA_TEMPLATE);
  $newSectionData.find('[data-header]').text(headers[0]);


  // Create section data items
  const maxVisits = data.reduce((visitsSum, item) => visitsSum + item[1], 0);
  const $dataItems = data
    .sort((firstItem, secondItem) => firstItem[1] < secondItem[1])
    .map(([itemName, itemVisits]) => {
      const $newSectionDataItem = $(SECTION_DATA_ITEM_TEMPLATE);
      const visitsPercentage = maxVisits > 0 && itemVisits > 0 ?
        ((itemVisits / maxVisits) * 100).toFixed(1) :
        0;

      // Set texts
      $newSectionDataItem.find('[data-name]').text(itemName);
      $newSectionDataItem.find('[data-total]').text(
        App.utils.thousandSeparator(itemVisits)
      );
      $newSectionDataItem.find('[data-percentage]').text(`${visitsPercentage}%`);

      // Set progress bar attributes
      $newSectionDataItem.find('.percentage-bar').css('width', `${visitsPercentage}%`);

      return $newSectionDataItem;
    });

  // Add items to section data
  $newSectionData.find('ul').html($dataItems);

  // Wrap all items in collapsible, except for the first two
  if (data.length > 2) {
    const collapseSufix = `collapse${headers[0]}`;

    // Append collapse button
    $newSectionData.find('ul').after(SECTION_DATA_COLLAPSE_BUTTON_TEMPLATE);

    // Update collapse button attributes
    $newSectionData
      .find('[data-toggle="collapse"]')
      .attr('data-target', `#${collapseSufix}`)
      .attr('aria-controls', collapseSufix);

    // Wrap items
    $newSectionData
      .find('ul li')
      .slice(2)
      .wrapAll(`<div class="collapse" id="${collapseSufix}"></div>`);
  }

  return $newSectionData;
};

/**
 * A function to create a section figures.
 *
 * @param  {object} indicators
 * @return {object}
 */
const createSectionFigures = (indicators) => {
  const $newSectionFigures = typeof indicators.event_total !== 'undefined' ?
    $(SECTION_FIGURES_TEMPLATE) :
    $(SECTION_EVENT_FIGURES_TEMPLATE);

  // Update figures
  [
    'favorites_total',
    'shared_on_social_networks_total',
    'attendances_total',
    'event_scheduled_on_google_calendar_total',
    'event_total'
  ].forEach((key) => {
    $newSectionFigures
      .find(`[data-${key.split('_').join('-')}]`)
      .text(App.utils.thousandSeparator(indicators[key]));
  });

  return $newSectionFigures;
};

/**
 * A function to display the data received from the API charts.
 *
 * @param  {object} $form
 * @param  {object} data
 */
const handleChartsData = ($form, data) => {
  // Update visits
  [
    'total_visits',
    'total_visits_with_users',
    'total_visits_without_users'
  ].forEach((key) => {
    $form
      .find(`[data-${key.split('_').join('-')}]`)
      .text(App.utils.thousandSeparator(data[key]));
  });

  // Create data sections
  const sectionsData = [
    'genders',
    'ages',
    'regions',
    'disciplines'
  ].map(sectionData => createSectionData(data[sectionData]));

  // Create chart section
  const sectionChart = $(SECTION_CHART_TEMPLATE).get(0);

  try {
    // Mobile Section
  let sectionMobile = '';

  // eslint-disable-line
  if (typeof mobile !== 'undefined')  {
    sectionMobile = createSectionDataMobile(mobile);
  }

  // Create figures sectionfhandleChartsData
  const sectionFigures = createSectionFigures(data.event_indicators);

  // Append sections to DOM
  // The results container must be inside .metrics-form class
  const $resultsContainer = $form.find(METRICS_RESULTS_CONTAINER_SELECTOR);

  if (!$resultsContainer.get(0)) {
    console.error(MISSING_CONTAINER_ERROR); // eslint-disable-line
    return;
  }

  // eslint-disable-line
  if (typeof mobile !== 'undefined') {
    $resultsContainer.html([sectionChart, sectionFigures, sectionMobile, ...sectionsData]);
  } else {
    $resultsContainer.html([sectionChart, sectionFigures, ...sectionsData]);
  }

  drawChart(data.dates, sectionChart);

  // Display selected date filter in data sections
  const selectdDateFilter = $form.find('[name="start_date"] :selected').text();
  $resultsContainer.find('[data-date-filter]').text(selectdDateFilter);
    
  } catch (error) {
    console.log(error);
  }
  
};

/**
 * A function to fecth data from API charts.
 *
 * @param  {object} $form
 * @param  {string} queryParams
 */
export const fetchChartsData = ($form, queryParams = null) => {
  const $resultsContainer = $form.find(METRICS_RESULTS_CONTAINER_SELECTOR);

  // Show loading
  $resultsContainer.html($(LOADING_TEMPLATE));

  $.ajax({
    type: 'GET',
    url: API_CHART_URL,
    data: queryParams,
    success: (data) => {
      handleChartsData($form, data);
    },
    error: (error) => {
      // Ups!
      $resultsContainer.html($(ERROR_TEMPLATE));
      console.error(REQUEST_ERROR, error); // eslint-disable-line
    }
  });
};