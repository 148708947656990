import {
  GA_SHARE_EVENT_ACTION,
  GA_SCHEDULE_EVENT_ACTION,
  GA_SOCIAL_MEDIA_CATEGORY
} from './constants';

export function sendEventToGA(event) {
  gtag('event', event.action, {
    event_category: event.category,
    event_label: event.label,
    value: event.value
  });
}

export function sendShareEventInSocialMediaEventToGA(label) {
  sendEventToGA({
    action: GA_SHARE_EVENT_ACTION,
    category: GA_SOCIAL_MEDIA_CATEGORY,
    label,
    value: 1
  });
}

export function sendScheduleEventEventToGA(label) {
  sendEventToGA({
    action: GA_SCHEDULE_EVENT_ACTION,
    category: GA_SOCIAL_MEDIA_CATEGORY,
    label,
    value: 1
  });
}
