$(() => {
  $('.behavior-display-container').each((i, item) => {
    const $this = $(item);
    const $trigger = $this.find('.behavior-display-trigger');
    const $affected = $this.find('.behavior-display-affected');
    const $target = $this.find('.behavior-display-target');
    const $cancel = $this.find('.behavior-display-cancel');

    const displayClass = $this.data('display-class') || 'd-block';

    const $venue = $('#id_venue');
    const $venueName = $('#id_venue_name');
    const $commune = $('#id_commune');

    if ($trigger.length && $target.length) {
      $trigger.on('click', (e) => {
        e.preventDefault();

        $venue.removeAttr('required');
        $venueName.attr('required', true);
        $commune.attr('required', true);

        if ($cancel.length) {
          $trigger.addClass('d-none');
        }

        if ($affected.length) {
          $affected.addClass('d-none');
        }

        $target.toggleClass(displayClass);
        $this.addClass('show');
      });

      if ($cancel.length) {
        $cancel.on('click', (e) => {
          e.preventDefault();

          $venue.attr('required', true);
          $venueName.removeAttr('required');
          $commune.removeAttr('required');

          $target.removeClass(displayClass);
          $trigger.removeClass('d-none');
          $this.removeClass('show');

          if ($affected) {
            $affected.removeClass('d-none');
          }
        });
      }
    }
  });
});
