import {
  SIDEBAR_ACTIVE_LINK_SELECTOR,
  SIDEBAR_SELECTOR,
  COLLAPSE_SELECTOR
} from '../common/constants';

$(() => {
  const $sidebar = $(SIDEBAR_SELECTOR);

  if (!$sidebar.length) return;

  // Find active link
  const $activeLink = $sidebar.find(SIDEBAR_ACTIVE_LINK_SELECTOR);

  if (!$activeLink.length) return;

  // Open collapse that contains $activeLink
  $activeLink
    .parents(COLLAPSE_SELECTOR)
    .first()
    .collapse('show');
});
