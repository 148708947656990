import { ELEMENT_WITH_TOOLTIP_SELECTOR } from '../common/constants';

$(() => {
  $(ELEMENT_WITH_TOOLTIP_SELECTOR).tooltip({
    placement: 'top',
    delay: {
      show: 750,
      hide: 0
    },
    container: 'body',
    boundary: 'viewport'
  });

  // Remove margin-top from html element if cms toolbar is on
  // and add the same value as padding-top,
  // because it breaks tooltips position
  if ($('html').hasClass('cms-toolbar-expanded')) {
    const marginTopValue = $('html').css('marginTop');

    $('html').css({
      paddingTop: marginTopValue,
      marginTop: 0
    });
  }
});
