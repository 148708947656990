// Styles
import '../scss/main.scss';

// Base
import '@gobdigital-cl/gob.cl';
import './forms';

// Plugins
import './plugins/smartresize';
import './plugins/carousel';


// Behaviors
import './behaviors/attend-event-btn';
import './behaviors/child-aspect-ratio';
import './behaviors/cropper';
import './behaviors/delete-request';
import './behaviors/display';
import './behaviors/email-default-text';
import './behaviors/favorite-btn';
import './behaviors/form-dynamic';
import './behaviors/form-control-update-location';
import './behaviors/input-image';
import './behaviors/input-map';
import './behaviors/metrics/index';
import './behaviors/nav-scroll';
import './behaviors/pagination-links';
import './behaviors/query-region';
import './behaviors/region-inputs';
import './behaviors/schedule-event';
import './behaviors/section-venue';
import './behaviors/sidebar';
import './behaviors/to-svg';
import './behaviors/toggle-class';
import './behaviors/tooltip';

// Others
import './home';
import './make-featured';
import './shame';
import './svg-icon';
import './maps';
