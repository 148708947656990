$(() => {
  $('.form-control-update-location').change(function updateLocationOnChange() {
    const $this = $(this);
    let location;

    // Get new location
    // Select
    if ($this.is('select')) {
      location = $this.find(':selected').data('location');
    }

    // Checkbox
    if ($this.is('[type="checkbox"]')) {
      location = $this.data('location');
    }

    if (!location) return;

    // Redirect to new location
    window.location.href = location;
  });
});
