import { DEFAULT_ICON_OPTIONS } from '../common/constants';

$(() => {
  const getGeocode = ($address, $commune, inputMap) => {
    // query to nominatim will be: "[address], [commune], Chile" for better results
    const queryItems = [];
    if ($address.length) {
      queryItems.push($address.val());
    }

    if ($commune.length) {
      const $option = $commune.find('option:selected');

      if ($option.val()) {
        queryItems.push($option.text());
      }
    }

    queryItems.push('Chile');

    axios
      .get('https://nominatim.openstreetmap.org/search', {
        params: {
          q: queryItems.join(', '),
          format: 'json'
        }
      })
      .then((res) => {
        if (res.data.length > 0) {
          inputMap.flyTo([
            res.data[0].lat,
            res.data[0].lon
          ], 17);
        }
      })
      .catch(error => console.log(error));
  };

  $('.input-map-container').each((i, item) => {
    const $container = $(item);
    const scope = $container.data('scope')
      ? `_${$container.data('scope')}`
      : '';

    const $map = $container.find(`#input_map${scope}`);
    const $lat = $container.find(`#id_lat${scope}`);
    const $lon = $container.find(`#id_lon${scope}`);

    const $form = $container.closest('form');
    const $address = $form.find(`#id_address${scope}`);
    const $commune = $form.find(`#id_commune${scope}`);

    // create map
    const inputMap = L.map($map[0], {
      scrollWheelZoom: 'center',
      doubleClickZoom: 'center',
      touchZoom: 'center'
    });

    $map.data('instance', inputMap);

    let mapMarker;

    // if editing, set the map center to the current coords, else default coords
    if ($lat.val() && $lon.val()) {
      const coords = [$lat.val(), $lon.val()];
      inputMap.setView(coords, 17);

      // create marker
      mapMarker = L.marker.svgMarker(
        coords,
        { iconOptions: DEFAULT_ICON_OPTIONS }
      )
        .addTo(inputMap);
    } else {
      inputMap.setView([-33.4377156, -70.6515013], 13);
    }

    // set tiles
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(inputMap);

    // move marker and set coords input values when the map moves
    if (!scope) {
      inputMap.on('move', (e) => {
        const {
          lat,
          lng
        } = e.target.getCenter();
        const coords = [lat, lng];

        if (mapMarker) {
          mapMarker.setLatLng(coords);
        } else {
          // create marker
          mapMarker = L.marker.svgMarker(
            coords,
            { iconOptions: DEFAULT_ICON_OPTIONS }
          )
            .addTo(inputMap);
        }

        $lat.val(lat);
        $lon.val(lng);

        inputMap.invalidateSize(); // ¯\_(ツ)_/¯
      });
    }

    if ($address.length) {
      // get coordinates from address and fly to that position
      $address.on('change', () => {
        if ($address.val()) {
          getGeocode($address, $commune, inputMap);
        }
      });
    }

    if ($commune.length) {
      // get coordinates also when the commune changes
      $commune.on('change', () => {
        if ($commune.val()) {
          getGeocode($address, $commune, inputMap);
        }
      });
    }

    setTimeout(() => {
      inputMap.invalidateSize();
    }, 1000);
  });

  $('.behavior-display-map').on('click', (e) => {
    const $map = $(e.currentTarget)
      .closest('form')
      .find('.input-map');

    $map.each((i, item) => {
      $(item).data('instance').invalidateSize();
    });
  });
});
