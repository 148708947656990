class Home {
  constructor() {
    // Get user position
    this.userPosition = null;

    navigator.geolocation.getCurrentPosition((position) => {
      this.userPosition = position;
      this.setView();
    });

    // Initialize map
    this.map = L.map('nearby-map');
    window.map = this.map;
    this.markers = {}; // 'layer_id' : [markers]
    this.setView();

    this.layerGroup = L.layerGroup();
    this.layerGroup.addTo(this.map);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map);
  }

  // Sets the center of the map and the zoom
  setView() {
    if (this.userPosition) {
      this.map.setView([
        this.userPosition.coords.latitude,
        this.userPosition.coords.longitude
      ], 13);
    } else {
      this.map.setView([
        -38.06539235133248,
        -74.35546875000001
      ], 4);
    }
  }

  // Gets events from backend and add markers
  getNearbyEvents(mobile) {
    // Call backend endpoint and pass all the inputs serialized
    const url = $('#nearby-map').data('nearby-events-url');

    if ($('#tags').find('input:checked').length === 0) {
      $('input[data-type="default_tag"]').prop('checked', true);
      tags_selected_status[$('input[data-type="default_tag"]').data('tag_id')] = true;
    }

    if ($('#tags').find('input:checked').length === 0 && typeof $('input[data-type="default_tag"]').data('type') !== 'undefined') {
      $('input[data-type="default_tag"]').prop('checked', true);
      tags_selected_status[$('input[data-type="default_tag"]').data('tag_id')] = true;
      filter_markers_by_tags($('input[data-type="default_tag"]')[0]);
    }

    axios.get(
      `${url}?${$('.nearby-input').serialize()}`, {
        params: {
          east_lon: this.map.getBounds().getEast(),
          west_lon: this.map.getBounds().getWest(),
          north_lat: this.map.getBounds().getNorth(),
          south_lat: this.map.getBounds().getSouth()
        }
      }).then((res) => {
      $.each(this.markers, (t_id, markers) => {
        $.each(markers, (idx, marker) => {
          map_layers[t_id].removeLayer(marker);
        });
      });

      $.each(map_layers, (t_id, layer) => {
        this.map.removeLayer(layer);
        if (!(t_id in this.markers)) {
          this.markers[t_id] = [];
        } else {
          this.markers[t_id].slice(0);
        }
      });

      this.draw_markers(res.data.places, mobile);

      $.each(map_layers, (key, m_layer) => {
        if (this.is_tag_selected(key)) m_layer.addTo(this.map);
      });
    }).catch((error) => {
      console.error(error);
    });
  }

  is_tag_selected(tag_id) {
    return tags_selected_status[tag_id];
  }

  draw_markers(data, mobile) {
    // Remove previous markers
    const opts = {
      fillOpacity: 1,
      iconSize: [25, 41],
      iconAnchor: mobile ? [12, 12] : [12, 41],
      popupAnchor: [1, -34]
    };

    $.each(data, (idx, place) => {
      if (place.tag === null) {
        return;
      }
      if (place.lat === null || place.lon === null) {
        return;
      }
      const tag_id = place.tag;

      if (!(tag_id in markers_icons)) {
        console.error('Requested icon doesnt exist.');
        return;
      }

      opts.color = markers_icons[tag_id].inactive_color;
      const popup_content = `${place.name}`;

      const marker = new L.Marker.SVGMarker([
        place.lat,
        place.lon
      ], { iconOptions: opts })
        // .addTo(this.layerGroup)
        .addTo(map_layers[tag_id])
        .on('click', () => {
          window.open(`${place.url}`, '_blank');
        })
        .on('mouseover', (evt) => {
          evt.target.setStyle({ color: markers_icons[tag_id].active_color, fillOpacity: 1 });
          evt.target.openPopup();
        })
        .on('mouseout', (evt) => {
          evt.target.setStyle({ color: markers_icons[tag_id].inactive_color, fillOpacity: 1 });
          evt.target.closePopup();
        })
        .bindPopup(popup_content, {
          className: `map_popup_${tag_id}`
        });
      this.markers[tag_id].push(marker);
    });
  }
}

$(() => {
  if ($('#nearby-map').length) {
    const home = new Home();
    $.each(tags_id, (idx, t_id) => {
      map_layers[t_id] = L.layerGroup();
    });
    if ($(window).width() < 992) {
      $(window).data('screen', 'mobile');
    } else {
      $(window).data('screen', 'desktop');
    }

    // Init map and init markers
    home.getNearbyEvents($(window).width() < 992);

    // Redraw markers when filters change
    $('.nearby-input').on('change', () => {
      if ($('#nearby-events').find('input:checked').length === 0) {
        $('#nearby-filter-today').prop('checked', true);
      }

      home.getNearbyEvents($(window).width() < 992);
    });

    // Redraw markers when map stops moving
    home.map.on('moveend', () => {
      home.getNearbyEvents($(window).width() < 992);
    });
    $(window).resize(() => {
      if ($(window).width() < 992) {
        if ($(window).data('screen') !== 'mobile') {
          home.getNearbyEvents(true);
          $(window).data('screen', 'mobile');
        }
      } else if ($(window).data('screen') !== 'desktop') {
        home.getNearbyEvents(false);
        $(window).data('screen', 'desktop');
      }
    });
  }

  // Open streaming modal
  const $modalContainer = $('#streaming-modal');
  if ($modalContainer.data('opened')) {
    $('#streaming-modal').modal('show');
  }

  $('#tags').find('input:checkbox').on('click', (evt) => {
    if ($('#tags').find('input:checked').length === 0 && typeof $('input[data-type="default_tag"]').data('type') !== 'undefined') {
      $('input[data-type="default_tag"]').prop('checked', true);
      tags_selected_status[$('input[data-type="default_tag"]').data('tag_id')] = true;
      filter_markers_by_tags($('input[data-type="default_tag"]')[0]);
    }

    filter_markers_by_tags(evt.target);
  });

  setTimeout(() => {
    if (!$('#nearby-filter-today').prop('checked')) {
      $('#nearby-filter-today').click();
    }
  }, 1);
  for (const obj of $('#tags').find('input:checked')) {
    setTimeout(() => filter_markers_by_tags(obj), 2);
  }
});

filter_markers_by_tags = function (obj) {
  const tag_id = $(obj).data('tag_id');
  const { checked } = obj;
  const map_layer = map_layers[tag_id];

  if (!(tag_id in tags_selected_status)) {
    tags_selected_status[tag_id] = undefined;
  }
  if (checked) {
    map.addLayer(map_layer);
    tags_selected_status[tag_id] = true;
  } else {
    map.removeLayer(map_layer);
    tags_selected_status[tag_id] = false;
  }
};
