import { sendShareEventInSocialMediaEventToGA } from './common/google-analytics-functions';
import {
  GA_SHARE_EVENT_IN_FACEBOOK_LABEL,
  GA_SHARE_EVENT_IN_TWITTER_LABEL,
  GA_SHARE_EVENT_VIA_MOBILE_LABEL
} from './common/constants';


$(() => {
  $('.form-check label').each((ignored, item) => {
    const $wrapper = $('<div/>', {
      class: 'custom-control custom-checkbox'
    });
    $(item).wrap($wrapper);

    const $checkbox = $(item).children().detach();

    $checkbox.addClass('custom-control-input');

    $(item)
      .addClass('custom-control-label')
      .prop('for', $checkbox.prop('id'))
      .parent()
      .prepend($checkbox);
  });

  // Social media sharing
  // If native sharer is present, hide fb/tw buttons
  if (navigator.share) {
    $('.social-share-title').removeClass('d-inline');
    $('.social-share-title').addClass('d-none');
    $('.social-share-facebook').addClass('d-none');
    $('.social-share-twitter').addClass('d-none');

    $('.social-share').click(() => {
      navigator.share({
        title: document.title,
        url: window.location.href
      });
    });

    // Send google analytics event
    sendShareEventInSocialMediaEventToGA(GA_SHARE_EVENT_VIA_MOBILE_LABEL);
  } else {
    // Otherwise, hide share button and call fb/tw sharers
    $('.social-share').addClass('d-none');

    $('.social-share-facebook').click(() => {
      const text = encodeURIComponent(document.title);
      window.open(
        `${'https://www.facebook.com/sharer/sharer.php?'
        + 'quote='}${text}&`
                + `u=${window.location.href}`,
        'pop',
        'width=600, height=400, scrollbars=no'
      );

      // Send google analytics event
      sendShareEventInSocialMediaEventToGA(GA_SHARE_EVENT_IN_FACEBOOK_LABEL);
    });

    $('.social-share-twitter').click(() => {
      const text = encodeURIComponent(document.title);
      window.open(
        `${'https://twitter.com/intent/tweet?'
        + 'text='}${text}&`
                + `url=${window.location.href}`,
        'pop',
        'width=600, height=400, scrollbars=no'
      );

      // Send google analytics event
      sendShareEventInSocialMediaEventToGA(GA_SHARE_EVENT_IN_TWITTER_LABEL);
    });
  }

  const facebookStreaming = $('.social-share-streaming-facebook');
  const twitterStreaming = $('.social-share-streaming-twitter');


  facebookStreaming.click(() => {
    const title = $('.streaming-title').text();
    const urlVideo = $('.iframe-streaming').children(':first-child').attr('src');
    window.open(
      `${'https://www.facebook.com/sharer/sharer.php?'
        + 'quote='}${title}&`
            + `u=${urlVideo}`,
      'pop',
      'width=400, height=400, scrollbars=no'
    );
  });

  twitterStreaming.click(() => {
    const title = $('.streaming-title').text();
    const urlVideo = $('.iframe-streaming').children(':first-child').attr('src');
    window.open(
      `${'https://twitter.com/intent/tweet?'
        + 'text='}${title}&`
            + `url=${urlVideo}`,
      'pop',
      'width=400, height=400, scrollbars=no'
    );
  });
});
