import {
  NAV_SCROLL_HORIZONTAL_SELECTOR,
  NAV_SCROLL_RIGHT_CLASS,
  NAV_SCROLL_LEFT_CLASS
} from '../common/constants';

function addHorizontalGradientsToElement(element) {
  const $element = $(element);
  const {
    scrollLeft,
    scrollLeftMax
  } = element;
  const itemIsScrolleableToLeft = scrollLeft > 0;
  const itemIsScrolleableToRight = scrollLeft < scrollLeftMax;

  // Add right gradient
  if (itemIsScrolleableToRight && !$element.hasClass(NAV_SCROLL_RIGHT_CLASS)) {
    $element.addClass(NAV_SCROLL_RIGHT_CLASS);
  }

  if (!itemIsScrolleableToRight && $element.hasClass(NAV_SCROLL_RIGHT_CLASS)) {
    $element.removeClass(NAV_SCROLL_RIGHT_CLASS);
  }

  // Add left gradient
  if (itemIsScrolleableToLeft && !$element.hasClass(NAV_SCROLL_LEFT_CLASS)) {
    $element.addClass(NAV_SCROLL_LEFT_CLASS);
  }

  if (!itemIsScrolleableToLeft && $element.hasClass(NAV_SCROLL_LEFT_CLASS)) {
    $element.removeClass(NAV_SCROLL_LEFT_CLASS);
  }
}

$(() => {
  $(NAV_SCROLL_HORIZONTAL_SELECTOR)
    .each((_, item) => {
      addHorizontalGradientsToElement(item);
    })
    .scroll((event) => {
      addHorizontalGradientsToElement(event.target);
    });

  $(window).resize(() => {
    $(NAV_SCROLL_HORIZONTAL_SELECTOR).each((_, item) => {
      addHorizontalGradientsToElement(item);
    });
  });
});
