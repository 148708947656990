(($, name) => {
  const debounce = (fn = () => {}, threshold = 100, execAsap) => {
    let timeout;

    return function debounced(...args) {
      const target = this;

      if (timeout) {
        clearTimeout(timeout);
      } else if (execAsap) {
        fn.apply(target, args);
      }

      timeout = setTimeout(() => {
        if (!execAsap) {
          fn.apply(target, args);
        }

        timeout = null;
      }, threshold);
    };
  };

  // eslint-disable-next-line no-param-reassign, func-names
  $.fn[name] = function (fn) {
    return fn
      ? this.on('resize', debounce(fn))
      : this.trigger('name');
  };
})(jQuery, 'smartresize');
