import { changeSelect2TextColor } from '../forms';

$(() => {
  // Unselect commune when selecting another region
  $('#id_region').on('change', function regionChangeHandler() {
    if ($(this).val()) {
      $('#id_commune').val(null).trigger('change');
    }
  });

  // Get communes from the selected region from server
  $('#id_commune').select2({
    ajax: {
      url: '/regions/communes/search/',
      dataType: 'json',
      delay: 250,
      data(params) {
        return {
          commune: params.term,
          regionId: $('#id_region').val()
        };
      },

      processResults(data) {
        return {
          results: data
        };
      },

      cache: true
    },
    width: '100%'
  });

  changeSelect2TextColor.call('#id_commune');
});
