$(() => {
  $('img.to-svg').each((i, item) => {
    const $this = $(item);

    const idAttr = $this.attr('id');
    const classAttr = $this.attr('class');
    const srcAttr = $this.attr('src');

    $.get(srcAttr, (data) => {
      let $svg = $(data).find('svg');

      if (idAttr) {
        $svg = $svg.attr('id', idAttr);
      }

      if (classAttr) {
        $svg = $svg
          .attr('class', classAttr)
          .removeClass('d-none');
      }

      $svg = $svg.removeAttr('xmlns:a');

      if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
        $svg.attr(`viewBox 0 0  ${$svg.attr('height')} ${$svg.attr('width')}`);
      }

      $this.replaceWith($svg);
    });
  });
});
