/**
  * A plugin to crop images when one is selected
  * Just allows one cropper in the page associated to
  * a file input with id `id_image` and a text input
  * with id `id_cropped_image`.
  */

import Cropper from 'cropperjs';

// Selectors
const CROPPER_CLASS_PREFIX = '.cropper-';
const CROPPER_ID_PREFIX = '#cropper';
const CROPPER_ACTION_ATTRIBUTE = 'data-cropper-action';

const CROPPER_IMG_SELECTOR = `${CROPPER_CLASS_PREFIX}img`;
const CROPPER_IMG_CONTAINER_SELECTOR = `${CROPPER_CLASS_PREFIX}img-container`;
const CROPPER_MODAL_SELECTOR = `${CROPPER_ID_PREFIX}Modal`;

const CROPPER_CONFIRM_SELECTOR = `[${CROPPER_ACTION_ATTRIBUTE}='confirm']`;
const CROPPER_ZOOM_IN_SELECTOR = `[${CROPPER_ACTION_ATTRIBUTE}='zoom_in']`;
const CROPPER_ZOOM_OUT_SELECTOR = `[${CROPPER_ACTION_ATTRIBUTE}='zoom_out']`;
const CROPPER_ROTATE_LEFT_SELECTOR = `[${CROPPER_ACTION_ATTRIBUTE}='rotate_left']`;
const CROPPER_ROTATE_RIGHT_SELECTOR = `[${CROPPER_ACTION_ATTRIBUTE}='rotate_right']`;
const CROPPER_RESET_SELECTOR = `[${CROPPER_ACTION_ATTRIBUTE}='reset']`;

$(() => {
  // Variables
  const $imageInput = $('input#id_image');
  const $croppedImageInput = $('input#id_cropped_image');
  const $preview = $('.input-image-preview');

  const $image = $(CROPPER_IMG_SELECTOR);
  const $imageContainer = $(CROPPER_IMG_CONTAINER_SELECTOR);
  const $modal = $(CROPPER_MODAL_SELECTOR);

  // Actions buttons
  const $confirmBtn = $(CROPPER_CONFIRM_SELECTOR);
  const $zoomInBtn = $(CROPPER_ZOOM_IN_SELECTOR);
  const $zoomOutBtn = $(CROPPER_ZOOM_OUT_SELECTOR);
  const $rotateLeftBtn = $(CROPPER_ROTATE_LEFT_SELECTOR);
  const $rotateRightBtn = $(CROPPER_ROTATE_RIGHT_SELECTOR);
  const $resetBtn = $(CROPPER_RESET_SELECTOR);

  let cropper;

  function clearCropper() {
    if (cropper) {
      cropper.destroy();
      cropper = null;
    }

    $imageContainer.removeClass('show');
  }

  // On select a new image show a cropper with it
  $imageInput.on('change', (e) => {
    // If there is an image, update cropper image and open modal
    const { files } = e.target;

    if (!files || files.length < 1) return;

    const [file] = files;
    const reader = new FileReader();

    reader.onload = () => {
      // Update $image source
      $image[0].src = reader.result;

      // Open modal
      $modal.modal('show');
    };
    reader.readAsDataURL(file);
  });

  // On shown modal, initialize cropper
  $modal.on('shown.bs.modal', () => {
    // Get $image aspect ratio,
    // it expects to be an arrray with 2 numbers
    const aspectRatio = $image.data('aspectRatio')[0]
      / $image.data('aspectRatio')[1];

    // Initialize cropperjs
    cropper = new Cropper($image[0], {
      aspectRatio,
      viewMode: 2,
      dragMode: 'move',
      zoomOnTouch: false,
      zoomOnWheel: false,
      wheelZoomRatio: false,
      toggleDragModeOnDblclick: false
    });

    $imageContainer.addClass('show');
  });

  // On show and hidden modal, destroy previous cropper
  $modal.on('show.bs.modal', clearCropper);
  $modal.on('hidden.bs.modal', clearCropper);


  // Actions buttons:

  // Confirm
  $confirmBtn.click(() => {
    // On confirm crop save the image in $imageInput and show it in $preview
    const canvas = cropper.getCroppedCanvas();

    // Change preview
    if ($preview.length > 0) {
      $preview.css('background-image', `url(${canvas.toDataURL()})`);
      $preview.addClass('input-image-preview-filled');
    }

    // Save base64
    $croppedImageInput.val(canvas.toDataURL());

    // Close modal
    $modal.modal('hide');
  });

  // Zoom in
  $zoomInBtn.click(() => {
    cropper.zoom(0.2);
  });

  // Zoom out
  $zoomOutBtn.click(() => {
    cropper.zoom(-0.2);
  });

  // Rotate left
  $rotateLeftBtn.click(() => {
    cropper.rotate(90);
  });

  // Rotate right
  $rotateRightBtn.click(() => {
    cropper.rotate(-90);
  });

  // Reset
  $resetBtn.click(() => {
    cropper.reset();
  });
});
