import { METRICS_FORM_SELECTOR } from './constants';
import { fetchChartsData } from './functions';

(function initializeMetrics() {
  if (!window.google) return;

  google.charts.load(
    'current',
    {
      packages: ['corechart'],
      language: 'es'
    }
  );

  google.charts.setOnLoadCallback(() => {
    $(METRICS_FORM_SELECTOR).each((_, form) => {
      const $form = $(form);

      // Initial fetch data
      fetchChartsData($form, $form.serialize());

      // Fetch data when any input changes
      $form.on('change', 'input, select', () => {
        fetchChartsData($form, $form.serialize());
      });
    });
  });
}());
