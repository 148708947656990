import { DEFAULT_MAPS_IDS, DEFAULT_ICON_OPTIONS } from './common/constants';

const initMapWithCoords = (mapId, [lat, lon]) => {
  const myMap = L.map(mapId).setView([lat, lon], 13);

  L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  }).addTo(myMap);

  L.marker.svgMarker([lat, lon], {
    iconOptions: DEFAULT_ICON_OPTIONS
  }).addTo(myMap);
};

$(() => {
  const mapsSelector = DEFAULT_MAPS_IDS.join(', ');

  $(mapsSelector).each((_, mapElement) => {
    const lat = $(mapElement).data('lat');
    const lon = $(mapElement).data('lon');

    initMapWithCoords(mapElement, [lat, lon]);
  });
});
